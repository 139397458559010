:root {
  /* --------------------------------------------
  VARIABLE NAMING GUIDELINES
  --[element]-[state]-[css-property]-[variant]
  --card-border;
  --card-hover-border;
  --card-hover-border-lg;
  --------------------------------------------- */

  /* Screens
  -------------------------------------------- */
  --screen-xs: 420px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1440px;
  --screen-3xl: 1920px;

  /* Spacing
  -------------------------------------------- */
  --space-xs: 4px;
  --space-sm: 12px;
  --space-md: 24px;
  --space-lg: 40px;
  --space-xl: 64px;
  --space-2xl: 128px;
  --space-3xl: 250px;

  /* Height
  -------------------------------------------- */
  --navbar-height: 72px;
  --navbar-lg: 620px;
  --navbar-md: 540px;
  --navbar-default: 260px;

  --header-height-xl: 43vw;
  --header-height-lg: 580px;
  --header-height-md: 540px;
  --header-height-sm: 340px;
  --header-height-xs: 260px;

  --button-height-xl: 60px;
  --button-height-lg: 50px;
  --button-height-md: 42px;
  --button-height-sm: 32px;

  --input-height: 42px;
  --video-title-height: 80px;

  --input-icon-height: 20px;

  /* Colors
  -------------------------------------------- */

  /* Palette ------------- */

  /* Neutral Colors */
  --color-white: #fff;

  /* Black Shades */
  --color-black-950: #000000; /* Pure black */
  --color-black-900: #1a1a1a; /* Merged from --sn-color-black */

  /* Special Whites */
  --color-off-white: #fdfdfc; /* Merged from --sn-color-white*/

  /* Orange Shades */
  --color-orange-950: #f4ba40;
  --color-orange-900: #f6c860;
  --color-orange-800: #f09665;
  --color-orange-700: #f8d688;
  --color-orange-600: #f3b08c;
  --color-orange-500: #fae3b0;
  --color-orange-400: #f6cbb2;
  --color-orange-300: #fbe5d9;
  --color-orange-200: #fdf2ec;
  --color-orange-100: #fef8eb;
  --color-orange-50: #fdf2ec;

  /* Gray Shades */
  --color-gray-900: #18181b;
  --color-gray-800: #27272a;
  --color-gray-700: #3f3f46;
  --color-gray-500: #71717a;
  --color-gray-300: #d4d4d8;
  --color-gray-100: #f4f4f5;

  /* Purple Shades */
  --color-purple-900: #350099;
  --color-purple-800: #401787; /* New color */
  --color-purple-700: #4e00e0;
  --color-purple-600: #7328f5; /* Merged from --sn-color-bright-purple-100 */
  --color-purple: #7328ff;
  --color-purple-400: #9258ff;
  --color-purple-300: #ccb4fb;
  --color-purple-200: #ebecfb; /* Merged from --sn-color-neutral-purple-30 */
  --color-purple-100: #f0e9ff;

  /* Pink Shades */
  --color-pink: #e10098;
  --color-pink-300: #ecb5da;
  --color-pink-100: #ffe5f7;

  /* Green Shades */
  --color-green: #00cf91;

  /* Mint Shades */
  --color-mint: #00e0a6;
  --color-mint-300: #98e9d4;
  --color-mint-100: #e3f3ef;

  /* Yellow Shades */
  --color-yellow: #ffe700;
  --color-yellow-300: #fff7aa;
  --color-yellow-100: #fffbd6;

  /* Gold Shades */
  --color-gold: #ffb81c;
  --color-gold-300: #ffe5ad;
  --color-gold-100: #fff6e2;

  /* Blue Shades */
  --color-blue: #147bd1;
  --color-blue-300: #84b3ff;
  --color-blue-200: #bddef5;
  --color-blue-100: #e7f0ff;

  /* Red Shades */
  --color-red: #e31c3d;
  --color-red-100: #fca5a5;

  /* UI Colors ----------- */
  --color-danger: var(--color-red);
  --color-danger-100: var(--color-red-100);

  --color-focus: var(--color-purple);

  /* Typography
  -------------------------------------------- */

  /* Font Size ----------- */
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 22px;
  --font-size-xl: 30px;
  --font-size-2xl: 42px;
  --font-size-3xl: 54px;
  --font-size-4xl: 72px;
  --font-size-5xl: 106px;

  /* Font Weight --------- */
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  /* Line Height --------- */
  --line-height-xxs: 14px;
  --line-height-xs: 16px;
  --line-height-sm: 18px;
  --line-height-md: 26px;
  --line-height-lg: 36px;
  --line-height-xl: 52px;
  --line-height-2xl: 84px;

  /* Borders
  -------------------------------------------- */
  --border-radius: 4px;
  --border-radius-lg: 8px;
  --border-width-sm: 1px;
  --border-width: 2px;
}

/* Containers
---------------------------------------------- */
body {
  margin: 0;
  padding: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Layout
---------------------------------------------- */
.gn-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gn-nav,
.gn-footer {
  flex: 0 0 auto;
}

.gn-main {
  flex: 1;
}

/* Tyopgraphy
---------------------------------------------- */

.gn-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* NOTE: font-italic refers to the Degular Display font; it is not a true italic of font-display */
.gn-page-header-lg {
  font-family: var(--font-degular-display), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  font-style: italic;
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-lg);
}
@media (min-width: 1024px) {
  .gn-page-header-lg {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-xl);
  }
}
@media (min-width: 1280px) {
  .gn-page-header-lg {
    font-size: var(--font-size-5xl);
    line-height: var(--line-height-2xl);
  }
}

.gn-page-header-sm {
  font-family: var(--font-degular-display), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-lg);
}

@media (min-width: 1024px) {
  .gn-page-header-sm {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-lg);
  }
}

@media (min-width: 1280px) {
  .gn-page-header-sm {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-xl);
  }
}

.gn-headline-lg {
  font-family: var(--font-degular-display), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-md);
}

@media (min-width: 768px) {
  .gn-headline-lg {
    font-size: var(--font-size-2xl);
    line-height: var(--line-height-lg);
  }
}

.gn-headline-md {
  font-family: var(--font-montserrat), sans-serif;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.625rem;
  line-height: 1.75rem;
}

.gn-headline {
  font-family: var(--font-degular), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gn-headline-sm {
  font-family: var(--font-degular), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-md);
}

.gn-subtitle {
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

@media (min-width: 768px) {
  .gn-subtitle {
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }
}

@media (min-width: 1024px) {
  .gn-subtitle {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-md);
  }
}

.gn-text-lg {
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-md);
}

.gn-text {
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.gn-text-sm {
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.gn-text-xs {
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}

.gn-caption {
  font-weight: var(--font-weight-black);
  font-family: var(--font-montserrat), sans-serif;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  text-transform: uppercase;
}

.gn-video-title {
  font-family: var(--font-degular), var(--font-montserrat), sans-serif;
  font-weight: var(--font-weight-black);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-md);
}

.gn-word-break {
  word-break: normal;
  overflow-wrap: anywhere;
}

.gn-transmogrifier-video .jwplayer.jw-flag-aspect-mode {
  height: 100vh !important;
}

.gn-transmogrifier-video .jw-preview {
  display: none !important;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Montserrat Fallback';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_4bc053 {font-family: 'Montserrat', 'Montserrat Fallback';font-style: normal
}.__variable_4bc053 {--font-montserrat: 'Montserrat', 'Montserrat Fallback'
}

@font-face {
font-family: 'degular';
src: url(/_next/static/media/2e99f7853f79f16a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: 'degular Fallback';src: local("Arial");ascent-override: 106.47%;descent-override: 26.02%;line-gap-override: 0.00%;size-adjust: 96.09%
}.__className_47f28c {font-family: 'degular', 'degular Fallback';font-weight: 800;font-style: normal
}.__variable_47f28c {--font-degular: 'degular', 'degular Fallback'
}

@font-face {
font-family: 'degularDisplay';
src: url(/_next/static/media/f5606e1873815f80-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}@font-face {font-family: 'degularDisplay Fallback';src: local("Arial");ascent-override: 113.71%;descent-override: 27.79%;line-gap-override: 0.00%;size-adjust: 89.96%
}.__className_85434a {font-family: 'degularDisplay', 'degularDisplay Fallback';font-weight: 800;font-style: italic
}.__variable_85434a {--font-degular-display: 'degularDisplay', 'degularDisplay Fallback'
}

